import React from 'react'
import './home.css'
import buyBonzo from './images/buy-bonzo.svg'
import footerDog from './images/dog.png'
import followTwitter from './images/follow-twitter.svg'
// import footerBorder from './images/footer-border.svg'
// import footerImageDevice from './images/footer-image-device.svg'
// import footerImage from './images/footer-image.svg'
import heroImage from './images/hero-main.png'
import joinTelegram from './images/join-telegram.svg'
// import heroImageMobile from './images/landing-mobile.svg'
import totalSupply from './images/total-supply.svg'
import lp from './images/lp-burned.svg'
import presale from './images/presale.svg'
import ownership from './images/ownership.svg'
// import logo from './images/logo.svg'


function Home() {
  return (
    <div>
        <h2 className="contract-address">Contract Address:</h2>
    <div className="hero"> 
        <a href="/" className="buy-bonzo"><img src={buyBonzo} alt="hero-group"/></a>
        <div className="hero-image-wrapper">
              <img className="hero-main-image" src={heroImage} alt="hero-group"/>
        </div>
        <a href="https://t.me/Bonzocommunity" rel="noreferrer" target="_blank" className="join-telegram"><img src={joinTelegram} alt="hero-group"/></a>
        <a href="https://x.com/Bonzodogsol" rel="noreferrer" target="_blank" className="follow-twitter"><img src={followTwitter} alt="hero-group"/></a>
    </div>

    <div className="about">
        <h2 className="about-header">The new ride on the Solana Blockchain</h2>
        <p className="about-bonzo">In the bustling world of blockchain technology, Bonzo Dog is the newest sensation— a digital canine with a unique twist. Created on the Solana blockchain, Bonzo isn't just any ordinary virtual pet; it was a rideable companion, offering users a whimsical journey through the digital landscape. With its floppy ears and pixelated fur, Bonzo captured the hearts of blockchain enthusiasts worldwide. From soaring over virtual mountains to zooming through decentralized cities, Bonzo becomes the ultimate adventure companion.</p>
    </div>

    <div className="bonzonomics">
        <div className="bonzonomics-wrapper">
        <h2 className="bonzonomics-header">Bonzonomics</h2>
        <div className="bonzonomics-wraper">
            <div className="bonzonomics-items">
              <img className="bonzonomics-item" src={totalSupply} alt="total-supply" />
              <img className="bonzonomics-item" src={presale} alt="presale" />
              <img className="bonzonomics-item" src={lp} alt="lp" />
              <img className="bonzonomics-item" src={ownership} alt="ownership" />
            </div>  
        </div>
        <img className="bonzonomics-footer-image" src={footerDog} alt="dog"/>
        </div>
    </div>
    <footer> 
        <div className="footer-border-1"></div>
        <div className="footer-border-2"></div>
    </footer>
    </div>
  )
}

export default Home